import { makeAutoObservable, runInAction } from "mobx";
import { openDB, IDBPDatabase } from "idb";
export class SidebarStore {
    private database: IDBPDatabase | null = null;
    private databaseName = "devtool";
    private tableName = "config";
    private key = "sidebar";
    isOpen: boolean = true;
    isLoading: boolean = true;
    
    constructor() {
        makeAutoObservable(this);
        this.initialize();
    };

    async initialize() {
        try {
            this.database = await openDB(this.databaseName, 1, {
                upgrade:(db) => {
                    if (!db.objectStoreNames.contains(this.tableName)) {
                        db.createObjectStore(this.tableName, { keyPath: "key" });
                    };
                },
            });

            const storedData = await this.database.get(this.tableName, this.key);
            runInAction(() => {
                this.isOpen = storedData?.isOpen ?? true;
                this.isLoading = false;
            })
        } catch (error) {
            console.error("Error initializing SidebarStore");
            this.isLoading = false;
        };
    };
    
    private async saveToDB (value: boolean) {
        try {
            if (this.database) {
                await this.database.put(
                    this.tableName, { 
                        key: this.key, 
                        isOpen: value
                    }
                );
            };
        } catch (error) {
            console.error('Error initializing SidebarStore');
        };
    }

    toggleSidebar = async() => {
        this.isOpen = !this.isOpen;
        await this.saveToDB(this.isOpen);
    };
};
