import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { Letter } from "react-letter";
import { extract } from "letterparser";
import { openDB } from "idb";
import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";

// Database configuration for persisting SMTP Mail Dev
const databaseName = "devtool";   // IndexedDB database name
const tableName = "config";       // Store name in the database
const key = "smtpmaildev";        // Key for the configuration object

interface Sender {
  id: string;
  email: string;
}

const SMTPMailDev: React.FC = () => {
  const [db, setDb] = useState(null);

  const [emails, setEmails] = useState([]);

  const [email, setEmail] = useState(null);

  // State to store the currently selected email, defaulting to the first email if available, otherwise null.
  const [selectedEmail, setSelectedEmail] = useState(emails[0] || null);

  // State to store the input for a recipient.
  const [recipient, setRecipient] = useState("");

  // State to store the input for a new sender.
  // const [senders, setSenders] = useState([]);
  const [senders, setSenders] = useState<{ [key: string]: Sender }>({});

  // const [tempSenders, setTempSenders] = useState([]);
  const [tempSenders, setTempSenders] = useState<{ [key: string]: Sender }>({

  });

  const [sender, setSender] = useState("");

  // Reference to the modal dialog element for direct manipulation of modal visibility.
  const modalSettingsRef = useRef<HTMLDialogElement>(null);

  const modalClearEmailsRef = useRef<HTMLDialogElement>(null);

  const [isEmailsLoading, setIsEmailsLoading] = useState(false);

  const [isEmailContentLoading, setIsEmailContentLoading] = useState(false);

  // State to store the last update time for the component, initialized with a default value.
  const [lastUpdate, setLastUpdate] = useState("Just now");

  const [initialTime, setInitialTime] = useState(Date.now());

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: "key" });
          }
        },
      });

      setDb(database);

      const storedData = await database.get(tableName, key);
      if (storedData) {
        setTempSenders(storedData.data.senders || {});
        setSenders(storedData.data.senders || {});
        setRecipient(storedData.data.recipient || "");
      } else {
        const id = uuidv4()
        setTempSenders(
          { [id]: { id: id, email: "sender@example.com" } }
        );

        setSenders(
          { [id]: { id: id, email: "sender@example.com" } }
        );

        setRecipient("recipient@example.com");
      }
    };

    initDB();
  }, []);

  const saveDataToDB = useCallback(async () => {
    if (db) {
      await db.put(tableName, { key, data: { senders: senders, recipient: recipient } });
    }
  }, [db, senders, recipient]);

  const handleInputSender = (value) => {
    setSender(value);
  };

  const handleAddSender = () => {
    if (!sender) {
      alert("Please fill sender!");
      return;
    };

    const emailExists = Object.values(tempSenders).some(senderData => senderData.email === sender);

    if (emailExists) {
      alert("This email is already exists!");
      return;
    };

    const id = uuidv4();
    const newSenderData = {
      id: id,
      email: sender
    };
    setTempSenders(prevSenders => ({ ...prevSenders, [id]: newSenderData }));
  };

  const handleDeleteSender = (id) => {
    setTempSenders((prevSenders) => {
      const updatedSenders = { ...prevSenders };
      delete updatedSenders[id];
      return updatedSenders;
    });

    setTempSenders((prevSenders) => {
      const updatedSenders = { ...prevSenders };
      delete updatedSenders[id];
      return updatedSenders;
    });
  };

  const handleSaveSenders = () => {
    setSenders(tempSenders);
    toast.success("Senders saved successfully");
  };

  useEffect(() => {
    saveDataToDB()
  }, [saveDataToDB, senders, recipient]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the time difference in milliseconds
      const elapsedTime = Date.now() - initialTime;

      // Convert the elapsed time to minutes, hours, and days
      const minutes = Math.floor(elapsedTime / 60000);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      // Determine the last update message based on the elapsed time
      let timeMessage = "Just now";
      if (minutes < 60) {
        timeMessage = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (hours < 24) {
        timeMessage = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else {
        timeMessage = `${days} day${days !== 1 ? 's' : ''} ago`;
      }

      setLastUpdate(timeMessage);
    }, 60000); // Update every minute (60000 ms)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [initialTime]);

  // Function to delete an email from the list by its id.
  // If the deleted email is currently selected, updates the selected email to the next available one or sets it to null if none are left.
  const deleteEmail = async (id: number) => {
    try {
      await axios.delete(
        `https://api.smtpbucket.com/emails/${encodeURIComponent(id)}`
      );
    } catch (error) {
      toast.error("Failed to delete email");
      return;
    }

    setLastUpdate("Just now");
    setInitialTime(Date.now());
    setEmails(emails.filter((email) => email.id !== id));
    setEmail(null);
    setSelectedEmail(null);
  };

  const clearEmails = async () => {
    closeModal("clearEmails")

    if (emails.length === 0) {
      toast.error("No emails to delete");
      return;
    };

    try {
      const emailList = emails.map((email: any) => email.id);

      await Promise.all(
        emailList.map((emailId) => deleteEmail(emailId))
      );

      setLastUpdate("Just now");
      setInitialTime(Date.now());
      setEmails([]);
      setSelectedEmail(null);

      toast.success("All emails cleared successfully");
    } catch (error) {
      toast.error("Failed to clear emails");
    }
  };

  // Function to open the modal dialog by calling the showModal method on the dialog element.
  const openModal = (type: string) => {
    switch (type) {
      case "settings":
        modalSettingsRef.current?.showModal();
        break;
      case "clearEmails":
        modalClearEmailsRef.current?.showModal();
        break;
      default:
        break;
    }
  };

  // Function to close the modal dialog by calling the close method on the dialog element.
  const closeModal = (type: string) => {
    switch (type) {
      case "settings":
        modalSettingsRef.current?.close();
        break;
      case "clearEmails":
        modalClearEmailsRef.current?.close();
        break;
      default:
        break;
    };
  };

  // Function to handle click events outside the modal, closing the modal if the click target matches the modal element.
  const handleOutsideClick = (event: React.MouseEvent<HTMLDialogElement>, type: string) => {
    if (modalSettingsRef.current && event.target === modalSettingsRef.current && type === "settings") {
      closeModal("settings");
    };

    if (modalClearEmailsRef.current && event.target === modalClearEmailsRef.current && type === "clearEmails") {
      closeModal("clearEmails");
    };
  };

  // Function to handle recipient input from user.
  const handleRecipient = (value) => {
    setRecipient(value);
    setSelectedEmail(null);
    setEmail(null);
  };

  /**
   * Fetches email data asynchronously from a specified API endpoint.
   * @param {string} id - The unique identifier of the email.
   * @returns {Promise<Object|Array>} - The email data if successful, an empty array otherwise.
   */
  const getEmail = async (id) => {
    try {
      const response = await axios.get(
        `https://api.smtpbucket.com/emails/${id}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return [];
    }
  };

  /**
   * Parses the email body to extract HTML and text content.
   *
   * @param {string} value - The raw email body.
   * @returns {Promise<Object>} - An object containing the parsed HTML and text content.
   */
  const parseEmail = async (value) => {
    const parsed = extract(value);
    return {
      html: parsed.html,
      text: parsed.text,
    };
  };

  /**
   * Fetches and formats email data, then sets the email state.
   *
   * @param {string} id - The unique identifier of the email.
   */
  const showEmail = async (id) => {
    setIsEmailContentLoading(true);
    const data = await getEmail(id);
    if (data) {
      const formattedEmail = {
        ...data,
        timeCreated: formatDate(data.timeCreated),
        body: await parseEmail(data.body),
      };
      setLastUpdate("Just now");
      setInitialTime(Date.now());
      setEmail(formattedEmail);
    }
    setIsEmailContentLoading(false);
  };

  // Function to fetch emails data asynchronously from a specified API endpoint.
  const getData = async (sender: string, recipient: string) => {
    try {
      const url = sender
        ? `https://api.smtpbucket.com/emails?sender=${encodeURIComponent(
          sender
        )}&recipient=${encodeURIComponent(recipient)}`
        : `https://api.smtpbucket.com/emails?recipient=${encodeURIComponent(
          recipient
        )}`;
      const response = await axios.get(url);
      return response.data.results || [];
    } catch (error) {
      return [];
    }
  };

  // Function to format from timestamp to human-readable date.
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  // useCallback hook to memoize the fetchData function, ensuring it only updates when dependencies change.
  // fetchData calls getData to fetch email data and updates the state if data is received.
  const fetchData = useCallback(async (senders, recipient) => {
    let allEmails: any[] = [];

    if (Object.keys(senders).length === 0) {
      // If no senders, fetch data only for recipient
      const recipientEmails = await getData(null, recipient);
      allEmails = recipientEmails;
    } else {
      // Fetch data for each sender concurrently
      const sendersList = Object.values(senders).map((sender: any) => sender.email);

      // Use Promise.all to fetch data for all senders concurrently
      const senderEmailPromises = sendersList.map((senderEmail) =>
        getData(senderEmail, recipient)
      );

      // Wait for all requests to complete
      const senderEmailsArray = await Promise.all(senderEmailPromises);

      // Flatten the array of results
      allEmails = senderEmailsArray.flat();
    }

    // Remove duplicate emails
    const uniqueEmails = Array.from(
      new Map(allEmails.map((email) => [email.id, email])).values()
    );

    // Format timestamps for all emails
    const parsedEmails = uniqueEmails.map((email: any) => ({
      ...email,
      timeCreated: formatDate(email.timeCreated),
    }));

    setLastUpdate("Just now");
    setInitialTime(Date.now());
    setIsEmailsLoading(false);
    setEmails(parsedEmails); // Update state with formatted emails
  }, []);

  useEffect(() => {
    if (recipient) {
      setIsEmailsLoading(true);
      fetchData(senders, recipient);
    };
  }, [recipient, senders, fetchData]);

  useEffect(() => {
    console.log("INI TEMP ", tempSenders);
    console.log("INI ASELI ", senders);
  }, [tempSenders, senders])

  return (
    <div className="flex">
      <div className="w-full mx-6 my-2">
        {/* Title */}
        <div className="flex items-center mb-3">
          <div className="title flex items-center font-bold space-x-2">
            <span>SMTP Maildev</span>
          </div>
        </div>
        {/* Button settings and clear */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <button className="btn btn-sm" onClick={() => openModal("settings")}>
              Settings
            </button>
            <button className="btn btn-sm" onClick={() => openModal("clearEmails")}>
              Clear
            </button>
            <span className="ml-2 text-sm">Last update: {lastUpdate}</span>
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="recipient" className="font-bold text-sm">
              Recipient:
            </label>
            <input
              value={recipient}
              onChange={(e) => handleRecipient(e.target.value)}
              type="text"
              id="recipient"
              placeholder="me@example.com"
              className="input input-bordered input-sm w-full max-w-xs"
            />
          </div>
        </div>

        <div className="flex space-x-4">
          {/* Left column: Email list with a large border surrounding it */}
          <div className="w-1/4 bg-gray-900 border border-gray-700 p-4 overflow-auto rounded-lg min-h-[78.5vh] max-h-[78.5vh]">
            {/* Email list with a border around each item */}
            {isEmailsLoading ? (
              <p className="text-center">Loading...</p>
            ) : emails.length > 0 ? (
              emails.map((email) => (
                <div
                  key={email.id}
                  className={`p-2 mb-2 relative border flex flex-col border-gray-700 rounded-lg cursor-pointer ${selectedEmail?.id === email.id
                    ? "bg-gray-700"
                    : "bg-gray-800 hover:bg-gray-700"
                    }`}
                  onClick={() => {
                    showEmail(email.id);
                    setSelectedEmail(email);
                  }}
                >
                  <div className="flex-1 pr-8">
                    <p className="font-bold flex-wrap break-words">{email.sender}</p>
                    <p className="text-gray-500">
                      {email.timeCreated}
                    </p>
                    <p className="truncate">{email.subject}</p>
                  </div>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteEmail(email.id);
                    }}
                    className="absolute bottom-2 right-2"
                  >
                    <img
                      src="/icons/delete.svg"
                      alt="delete"
                      className="h-4 w-4 text-gray-500 hover:text-gray-500"
                    />
                  </button>
                </div>
              ))
            ) : (
              <p className="text-center">No emails</p>
            )}
          </div>

          {/* Right column: Email detail */}
          <div className="w-3/4 p-4 bg-gray-900 border border-gray-700 rounded-lg">
            {isEmailContentLoading ? (
              <p className="text-center">Loading...</p>
            ) : email ? (
              <div>
                <div className="mb-4 flex flex-col">
                  <p className="flex justify-between">
                    <span>
                      <strong>Sender:</strong> {email.sender}
                    </span>
                    <a href={`https://www.smtpbucket.com/emails/${email.id}`} target="_blank" rel="noopener noreferrer">
                      <button className="btn btn-sm">
                        Detail
                      </button>
                    </a>
                  </p>
                  <p>
                    <strong>Subject:</strong> {email.subject}
                  </p>
                  <p>
                    <strong>Timestamp:</strong> {email.timeCreated}
                  </p>
                </div>
                <div className="-mx-4 border-t border-gray-700 p-4">
                  {email.html ? (
                    <Letter html={email.body.html} className="max-h-[61vh] overflow-y-scroll" />
                  ) : (
                    <p>{email.body.text}</p>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-center">No email selected</p>
            )}
          </div>
        </div>

        {/* Modal for Clear Email Confirmation */}
        <dialog
          ref={modalClearEmailsRef}
          className="modal z-50"
          onClick={(e) => handleOutsideClick(e, "clearEmails")}
        >
          <div className="modal-box w-3/12 flex flex-col">
            <h3 className="text-white text-lg mb-1 fontb">Confirm</h3>
            <p className="text-sm">
              Are you sure to delete all this emails?
            </p>
            <div className="flex justify-end mt-3">
              <button className="btn btn-sm" onClick={() => { clearEmails() }}>
                Yes
              </button>
            </div>
          </div>
        </dialog>

        {/* Modal for settings */}
        <dialog
          ref={modalSettingsRef}
          className="modal z-50"
          onClick={(e) => handleOutsideClick(e, "settings")}
        >
          <div className="modal-box">
            <h3 className="text-white text-lg mb-1">Settings</h3>
            {/* Tab list */}
            <div role="tablist" className="tabs tabs-lifted">
              {/* Senders tab */}
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Sender"
                defaultChecked
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                {/* Input Sender */}
                <div className="flex items-center mb-4">
                  <input
                    type="text"
                    className="input input-bordered input-sm flex-grow mr-2"
                    placeholder="sender@example.com"
                    value={sender}
                    onChange={(e) => handleInputSender(e.target.value)} // Update state input

                  />
                  <button
                    className="btn btn-sm"
                    onClick={() => handleAddSender()}>+
                  </button>
                </div>

                {/* Senders section */}
                <div className="mt-2 max-h-[25vh] min-h-[25vh]">
                  {Object.keys(tempSenders).map((key) => {
                    const sender = tempSenders[key];
                    return (
                      <div
                        key={sender.id}
                        className="flex justify-between items-center gap-2"
                      >
                        <div className="p-2 w-full">
                          <p>{sender.email}</p>
                        </div>
                        <button
                          className="p-2"
                          onClick={() => handleDeleteSender(sender.id)}
                        >
                          <img
                            src="/icons/delete.svg"
                            alt="delete"
                            className="h-5 w-5"
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
                {/* Save button to trigger the addEmail function */}
                <div className="flex justify-start mt-2">
                  <button className="btn btn-sm" onClick={() => handleSaveSenders()}>
                    Save
                  </button>
                </div>
              </div>

              {/* Guide tab */}
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Guide"
              />
              <div
                role="tabpanel"
                className="tab-content bg-base-100 border-base-300 rounded-box p-6"
              >
                <div className="flex flex-row flex-wrap gap-2 text-sm">
                  <p>
                    Here is the SMTP Dev configuration, the service provided by
                    <a href="https://www.smtpbucket.com" target="_blank" rel="noopener noreferrer" className="inline-block">
                      https://www.smtpbucket.com
                    </a>.
                  </p>
                  <pre>
                    <code>
                      host: mail.smtpbucket.com
                      <br />
                      port: 8025
                      <br />
                      username: &lt;blank&gt;
                      <br />
                      password: &lt;blank&gt;
                    </code>
                  </pre>
                  <p>
                    No auth needed, and use SSL. To check make sure you list all the Sender
                    email on the config and set the Recipient email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      </div>
    </div>
  );
};

export default SMTPMailDev;
