import React, { useCallback, useEffect, useState, } from "react";
import { useLocation } from "react-router-dom";
import sampleLorem from "../assets/json/lorem.json";
import QRCode from "qrcode";
import CopyButton from "../components/CopyButton";
import toast from "react-hot-toast";

const sizeOptions = [
  { label: "0.5x (500px x 500px)", value: 500, scale: 0.5 },
  { label: "1x (1000px x 1000px)", value: 1000, scale: 1 },
  { label: "2x (2000px x 2000px)", value: 2000, scale: 2 },
];

const templates = {
  "(Select Template)": "",
  URL: "https://www.example.com",
  Email: "mailto:name@example.com?subject=your+subject&body=your+body",
  Phone: "tel:+62111222333444",
  SMS: "sms:+62111222333444",
  Whatsapp: "https://wa.me/62111222333444",
  WiFi: "WIFI:T:WPA;\nS:Taksu Dev 1;\nP:12345678;\nH:;\n;",
};

export default function QRCodeGenerator() {
  const [inputText, setInputText] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [selectedSize, setSelectedSize] = useState(sizeOptions[1].value);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isGoogleDriveLink, setIsGoogleDriveLink] = useState(false);
  const [qrColor, setQrColor] = useState('#000000');
  const [qrBackgroundColor, setQrBackgroundColor] = useState('#FFFFFF')

  // Function to generate the QR code based on the current input and settings.
  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      inputText,
      {
        width: selectedSize,
        margin: 1,

        color: {
          dark: qrColor,
          light: qrBackgroundColor
        }
      },
      (err, newText) => {
        if (err) return console.error(err); // Handle potential errors during generation
        setQrCode(newText); //Update the state with the generated QR code URL
      }
    );
  }, [inputText, selectedSize, qrColor, qrBackgroundColor]);

  // Effect to handle query parameters from the URL (e.g., base64-encoded input)
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const input = query.get('input');  // Extract 'input' parameter from URL
    console.log("Query Parameter:", input);
    if (input) {
      try {
        // Decode Base64
        const decoded = atob(input);  // Decode the base64-encoded input
        setInputText(decoded);  // Set the decoded input as the QR content

      } catch (error) {
        console.error("Error decoding Base64:", error);
      }
    }
  }, [location.search]);

  // Effect to regenerate the QR code when input changes
  useEffect(() => {
    generateQRCode();
  }, [inputText, generateQRCode, qrBackgroundColor,]);
  console.log("Generating QR Code for:", inputText);

  // Handle input change (text area or dropdown selection)
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.value;
    if (e.target.tagName === "SELECT") {
      setSelectedTemplate(value);
      setInputText(templates[value]);
    } else {
      setInputText(value);
    }
    generateQRCode();
  };

  // Function to paste clipboard content into the input field
  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  // Function to set a sample text from a local JSON file
  const handleSampleFromJson = async () => {
    setInputText(sampleLorem[0]);
  };

  // Function to clear the input field
  const handleClear = async () => {
    setInputText("");
  };

  // Function to generate and copy a Base64-encoded URL
  const handleCopyURL = async () => {
    const base64Content = btoa(inputText);
    const directURL = `https://devtool.internal.taksu.tech/qr-code-generator?input=${base64Content}`;

    // Copy the URL to the clipboard with a success/failure toast notification
    if (navigator.clipboard) {
      navigator.clipboard.writeText(directURL)
        .then(() => toast.success('URL successfully copied to clipboard!'))
        .catch(err => toast.error('Failed to copy URL:', err));
    } else {
      console.error('Clipboard copy feature is not supported in this browser.');
    }
  }

  // Function to extract the direct download link from a Google Drive URL
  const getDirectDownloadLink = (shareLink: string) => {
    const match = shareLink.match(/https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/view/g);
    if (match) {
      const fileId = match[0].match(/https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/view/)[1];
      const directDownloadLink = `https://drive.google.com/uc?id=${fileId}&export=download`;
      console.log("Direct download link:", directDownloadLink);

      return directDownloadLink;
    }
    return null;
  };

  // Function to copy the generated QR code image to the clipboard
  const copyImageToClipboard = async () => {
    try {
      // Image element
      const img = document.createElement('img');
      img.src = qrCode ? qrCode : '/qrcode_example.png';

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject; // Handle image load error
      });

      // Canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      context.drawImage(img, 0, 0);

      const dataUrl = canvas.toDataURL('image/png');  // Get the image data URL

      // Copy to clipboard
      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': fetch(dataUrl).then(res => res.blob())
        })
      ]);

      toast.success('Image Copied to Clipboard!');
    } catch (error) {
      console.error('Failed to Copy Image to Clipboard', error);
      toast.error('Failed to Copy Image to Clipboard');
    }
  };


  // Effect to check if the input is a Google Drive link and generate a QR code for the direct download link
  useEffect(() => {
    const directDownloadLink = getDirectDownloadLink(inputText);
    if (directDownloadLink !== null) {
      QRCode.toDataURL(
        directDownloadLink,
        {
          width: selectedSize,
          margin: 1,
        },
        (err, newText) => {
          if (err) return console.error(err);
          setQrCode(newText);
        }
      );
      setIsGoogleDriveLink(true);
    } else {
      setIsGoogleDriveLink(false);
    }
  }, [inputText, selectedSize]);

  // Re-generate the QR code when key properties change (input text, color, size, etc.)
  useEffect(() => {
    generateQRCode();
  }, [inputText, qrColor, selectedSize, generateQRCode, qrBackgroundColor]);

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">QR Code Generator</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <div className="flex gap-2 items-center mb-2">
              <div>Content:</div>
              <button className="btn btn-xs  mr-1" onClick={handlePasteFromClipboard}>
                Clipboard
              </button>
              <button className="btn btn-xs  mr-1" onClick={handleSampleFromJson}>
                Sample
              </button>
              <button className="btn btn-xs  mr-1" onClick={handleClear}>
                Clear
              </button>
              <select className="select select-bordered select-xs " value={selectedTemplate} onChange={handleInputChange}>
                {Object.keys(templates).map((template) => (
                  <option key={template} value={template} className="btn btn-sm mr-2">
                    {template}
                  </option>
                ))}
              </select>
              <button className="btn btn-xs  mr-1" onClick={handleCopyURL}>
                Copy Direct URL📋
              </button>

              {
                isGoogleDriveLink === true && (
                  <div className="btn btn-xs ml-2">
                    <CopyButton text={getDirectDownloadLink(inputText)} />
                  </div>
                )
              }
            </div>

            <textarea
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white"
              placeholder="Enter text here"
              onChange={handleInputChange}
              value={inputText}
            />
            <div className="relative flex justify-end">Input String Length: {inputText.length} bytes</div>
          </div>

          <div className="flex flex-col gap-8 items-center justify-center place-self-center mt-14">
            <div className="flex flex-col gap-8 items-center justify-center place-self-center mt-14">
              {/* Show direct download for Google Drive link */}
              {
                isGoogleDriveLink === true && (
                  <div className="aspect-square h-[37vh] max-h-[78.5vh] relative">
                    <img src={qrCode ? qrCode : "/qrcode_example.png"} className="aspect-square rounded bg-white" alt="" width={"100%"} />
                    <div className="absolute inset-0 flex items-center justify-center">
                      <SvgGoogleDrive width={50} height={50} />
                    </div>
                  </div>
                )
              }

              {/* Normal QR code */}
              {
                isGoogleDriveLink === false && (
                  <div className="aspect-square h-[37vh] max-h-[78.5vh]">
                    <img src={qrCode ? qrCode : "/qrcode_example.png"}
                      className="aspect-square rounded bg-white" alt="QR Code" width={"100%"} />

                  </div>
                )
              }
              <div>
                <p>Select Size:</p>
                <select className="bg-gray-900 text-white p-2 rounded" value={selectedSize} onChange={(e) => setSelectedSize(Number(e.target.value))}>
                  {sizeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex gap-4 ml-14">
                <a href={qrCode ? qrCode : "/qrcode_example.png"} download={`qrcode_${selectedSize}.png`} className="btn btn-sm">
                  Save QR as PNG
                </a>
                <button onClick={copyImageToClipboard} className="btn btn-sm">Copy Image to Clipboard</button>
              </div>

              <div className=" max-w-md mx-auto">
                <div tabIndex={0} className="collapse collapse-arrow border border-base-300 rounded-box text-slate-700 text-center bg-slate-100">
                  <input type="checkbox" className="peer" />
                  <div className="collapse-title text-xl font-medium bg-slate-100">
                    Choose QR Color
                  </div>
                  <div className="collapse-content">
                    <div className="mt-2 flex gap-2">
                      <SvgQrIcons
                        qrColor="#000000"
                        qrBackgroundColor="#fbcc4b"
                        onClick={() => {
                          setQrColor('#fbcc4b');
                          setQrBackgroundColor('#000000');
                        }}
                      />
                      <SvgQrIcons
                        qrColor="#d57bd2"
                        qrBackgroundColor="#ffffff"
                        onClick={() => {
                          setQrColor('#d57bd2');
                          setQrBackgroundColor('#ffffff');
                        }}
                      />

                      <SvgQrIcons
                        qrColor="#000000"
                        qrBackgroundColor="#ffffff"
                        onClick={() => {
                          setQrColor('#000000');
                          setQrBackgroundColor('#ffffff');
                        }}
                      />

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div >
    </div>
  );
}

const SvgGoogleDrive = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Google Drive"
      viewBox="0 0 512 512"
      {...props}
    >
      <rect width={512} height={512} fill="#fff" rx="15%" />
      <path fill="#4587f4" d="M468 299H112v120h288" />
      <path fill="#1da261" d="M187 51 44 299l68 120L325 51" />
      <path fill="#ffcf48" d="m325 51 143 248H331L187 51" />
    </svg>
  )
}

// QR code SVG
const SvgQrIcons = ({ qrColor, qrBackgroundColor, onClick }) => {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70px" height="70px"
        viewBox="0 0 150 150"
        fill={qrBackgroundColor}
        className="qr-icon"
        strokeWidth="3"
      >
        <rect
          width="150px"
          height="150px"
          fill={qrBackgroundColor}
          rx="10"
        />
        <g transform="translate(15, 15)"> {/*QR in center bg*/}
          <path
            fill={qrBackgroundColor}
            stroke={qrColor}
            className="st0"
            d="M0.18,0h44.63v44.45H0.18V0L0.18,0z M111.5,111.5h11.38v11.2H111.5V111.5L111.5,111.5z M89.63,111.48h11.38 
             v10.67H89.63h-0.01H78.25v-21.82h11.02V89.27h11.21V67.22h11.38v10.84h10.84v11.2h-10.84v11.2h-11.21h-0.17H89.63V111.48 
             L89.63,111.48z M55.84,89.09h11.02v-11.2H56.2v-11.2h10.66v-11.2H56.02v11.2H44.63v-11.2h11.2V22.23h11.38v33.25h11.02v11.2h10.84 
             v-11.2h11.38v11.2H89.63v11.2H78.25v22.05H67.22v22.23H55.84V89.09L55.84,89.09z M111.31,55.48h11.38v11.2h-11.38V55.48 
             L111.31,55.48z M22.41,55.48h11.38v11.2H22.41V55.48L22.41,55.48z M0.18,55.48h11.38v11.2H0.18V55.48L0.18,55.48z M55.84,0h11.38 
             v11.2H55.84V0L55.84,0z M0,78.06h44.63v44.45H0V78.06L0,78.06z M10.84,88.86h22.95v22.86H10.84V88.86L10.84,88.86z M78.06,0h44.63 
             v44.45H78.06V0L78.06,0z M88.91,10.8h22.95v22.86H88.91V10.8L88.91,10.8z M11.02,10.8h22.95v22.86H11.02V10.8L11.02,10.8z"
          />
        </g>
      </svg>
    </div>
  )
}