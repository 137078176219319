import { useState, useEffect, useCallback, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { EditorState } from "@codemirror/state";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { jsonLanguage } from "@codemirror/lang-json";
import CopyButton from "../components/CopyButton";
import { throttle } from "lodash";

import sampleJSON from "../assets/json/sample.json";

interface ITextStat {
  word: number,
  char: number,
  size: number
}

export default function JsonFormatter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [invalidJson, setInvalidJSON] = useState("");
  const [spaces, setSpaces] = useState(2);
  const [dividerWidth, setDividerWidth] = useState(50);
  const [textStats, setTextStats] = useState<ITextStat>();
  const isResizing = useRef(false);
  const startX = useRef(0);
  const startWidth = useRef(0);

  const decodeFromBase64 = (data) => {
    try {
      return atob(data);
    } catch (error) {
      console.error("Failed to decode data:", error);
      return null;
    }
  };

  // Waiting for next instruction.
  // const encodeToBase64 = (data) => {
  //   return btoa(data);
  // };

  const calculateTextStats = (text: string) => {
    const wordCount = text.trim().split(/\s+/).length;
    const charCount = text.length;
    const sizeCount = new TextEncoder().encode(text).length / 1024;

    return {
      word: wordCount,
      char: charCount,
      size: sizeCount,
    };
  };

  const handleMouseDown = (e) => {
    isResizing.current = true;
    startX.current = e.clientX;
    startWidth.current = dividerWidth;
    e.preventDefault();
  };

  const handleMouseMove =
    throttle((e) => {
      if (!isResizing.current) {
        return;
      };

      const deltaX = e.clientX - startX.current;
      const newWidth = startWidth.current + (deltaX / window.innerWidth) * 100;
      setDividerWidth(Math.min(75, Math.max(25, newWidth)));
    },
      16);

  const handleMouseUp = () => {
    isResizing.current = false;
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, handleMouseMove]);


  useEffect(() => {
    if (inputText.trim() === "") {
      setOutputText("");
      setInvalidJSON("");
      return;
    }

    try {
      const formattedJSON = JSON.stringify(JSON.parse(inputText), null, spaces);
      const calculatedTextStats = calculateTextStats(formattedJSON);
      setTextStats(calculatedTextStats);
      setOutputText(formattedJSON);
      setInvalidJSON("");
    } catch (error) {
      setOutputText(inputText);
      setTextStats(null);
      setInvalidJSON("Invalid JSON");
    }
  }, [inputText, spaces, invalidJson]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const data = queryParams.get("import_from_base64");
    if (data) {
      const decodedData = decodeFromBase64(data);
      setInputText(decodedData);
    }
  }, []);

  const handleInputChange = useCallback((value, viewUpdate) => {
    setInputText(value);
  }, []);

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  const handleSampleFromJson = async () => {
    setInputText(JSON.stringify(sampleJSON, null, 2));
  };

  const handleClear = async () => {
    setInputText("");
    setTextStats(null);
  };

  const copyOutputToInput = () => {
    setInputText(outputText);
  };
  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">JSON Formatter</div>
        <div className="flex">
          <div className="flex flex-col" style={{ width: `${dividerWidth}%` }}>
            <div className="flex mb-4 items-center">
              <span>Input:</span>
              <div className="flex ml-3 gap-1">
                <button className="btn btn-xs" onClick={handlePasteFromClipboard}>
                  Clipboard
                </button>
                <button className="btn btn-xs" onClick={handleSampleFromJson}>
                  Sample
                </button>
                <button className="btn btn-xs" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>

            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[78.5vh] max-h-[78.5vh]"
              placeholder="Enter text here"
              extensions={[EditorView.lineWrapping]}
              value={inputText}
              onChange={handleInputChange}
              height="78.5vh"
              theme={dracula}
            />
          </div>

          <div
            className="cursor-col-resize w-[16px] min-h-[78.5vh] max-h-[78.5vh]"
            onMouseDown={handleMouseDown}
          >
          </div>

          <div className="flex flex-col flex-grow" style={{ width: `${100 - dividerWidth}%` }}>
            <div className="flex mb-4 items-center">
              <span>Output:</span>
              <div className="flex ml-auto gap-1 justify-end">
                <button className="btn btn-xs" onClick={copyOutputToInput}>
                  Use Output as Input
                </button>
                <select className="select select-bordered select-xs" value={spaces} onChange={(e) => setSpaces(Number(e.target.value))}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                    <option key={num} value={num}>
                      {num} Spaces
                    </option>
                  ))}
                </select>
                <CopyButton text={outputText} />
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]"
              extensions={[EditorView.editable.of(false), EditorView.lineWrapping, EditorState.readOnly.of(true), jsonLanguage]}
              theme={dracula}
              value={invalidJson ? invalidJson : outputText}
              height="78.5vh"
            />
          </div>
        </div>
        <div className="w-full flex justify-end items-center mt-3">
          <span>Words: {textStats ? textStats.word : 0}</span>
          <span className="mx-2">|</span>
          <span>Chars: {textStats ? textStats.char : 0}</span>
          <span className="mx-2">|</span>
          <span>Size: {textStats ? textStats.size.toFixed(2) : 0} KB</span>
        </div>
      </div>
    </div>
  );
}
