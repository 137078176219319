import React, { useState, useEffect, useMemo, useCallback } from "react";
import DeleteButton from "../components/DeleteButton";
import WorkInProgressBadge from "../components/WorkInProgressBadge";
import mqtt from "precompiled-mqtt";
import { openDB } from "idb";
import { md5 } from "hash-wasm";

/*======================================
 * Configuration: Constants and Defaults
 *======================================*/

// Database configuration for persisting URL data
const databaseName = "devtool";   // IndexedDB database name
const tableName = "config";       // Store name in the database
const key = "pomodoro";           // Key for the configuration object

interface TeamTimer {
  id: string;
  username: string;
  status: string;
  created_at: number;
};

interface IUser {
  id: string;
  email: string;
  username: string;
};

const Pomodoro: React.FC = () => {
  const [time, setTime] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [newTask, setNewTask] = useState<string>("");
  const [tasks, setTasks] = useState<{ name: string; completed: boolean }[]>([]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [taskEditText, setTaskEditText] = useState<string>("");
  const [ambientSound, setAmbientSound] = useState("Ocean Sound 1");
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioInstance, setAudioInstance] = useState<HTMLAudioElement | null>(null);
  const [activeMode, setActiveMode] = useState<string | null>(null);
  const [teamTimers, setTeamTimers] = useState<{ [key: string]: TeamTimer }>({});
  const [user, setUser] = useState<IUser | null>(null);
  const [client, setClient] = useState(null);
  const [db, setDb] = useState(null);
  const [tempEmail, setTempEmail] = useState<string | null>("");
  const [tempUsername, setTempUsername] = useState<string | null>("");

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: "key" });
          }
        },
      });

      setDb(database);

      const storedData = await database.get(tableName, key);
      if (storedData) {
        setTeamTimers(storedData.data || {});
        setUser(storedData.user || {});
        setTempEmail(storedData.user.email || null);
        setTempUsername(storedData.user.username || null);
      }
    };

    initDB();
  }, []);

  const saveDataToDB = useCallback(async () => {
    if (db) {
      await db.put(tableName, { key, data: teamTimers, user: user });
    }
  }, [db, user, teamTimers]); // List teamTimers as a dependency  


  // This function checks if both the email and username are provided.
  // If either is missing, an alert will notify the user to enter both. 
  // If both values are present, the input values are passed to the respective
  // handlers and a success message is displayed.
  const handleSaveUser = () => {
    if (!tempEmail || !tempUsername) {
      alert('Please enter both email and username!');
      return;
    };
    handleInputEmail(tempEmail);
    handleInputUsername(tempUsername);

    alert('Saved successfully!');
  };

  // Handles the email input, hashes the email using MD5, and updates the user state.
  // If the user already has an ID, it updates the email. Otherwise, it generates a new ID based on the MD5 hash of the email.
  const handleInputEmail = async (value: string) => {
    const emailHash = await md5(value);

    // Update the user state based on whether an ID already exists or not
    setUser((prevUser) => {
      // If the user doesn't have an ID, generate a new ID from the MD5 hash of the email
      return {
        ...prevUser,
        email: value,
        id: emailHash,
      };
    });
  };

  // Handles the username input and updates the user state with the new username.
  const handleInputUsername = (value: string) => {
    setUser((prevUser) => ({
      ...prevUser,
      username: value,
    }));
  };

  // Reciver Message
  useEffect(() => {
    // Connect to MQTT broker using WebSocket
    const client = mqtt.connect(process.env.REACT_APP_MQTT_HOST);

    client.on('connect', () => {
      console.log('Connected to MQTT broker over WebSocket');

      // Subscribe to a topic with a wildcard (receive all messages under 'multipomodoro')
      client.subscribe('multi-pomodoro/#', (err) => {
        if (err) {
          console.log('Failed to subscribe:', err);
        } else {
          console.log('Subscribed to multi-pomodoro/#');
        }
      });
    });

    // Handling messages from the subscribed topics
    client.on('message', (topic, message) => {
      try {
        // Parse the received message as JSON
        const parsedMessage = JSON.parse(message.toString());

        setTeamTimers(prevTeamTimers => {
          // Retrieve the existing data for the user from the current state
          const existingData = prevTeamTimers[parsedMessage.user_id];

          // Check if the existing data exists and if the new message has a newer timestamp
          if (existingData && existingData.created_at >= parsedMessage.created_at) {
            // Return the previous state without any changes if the timestamp is not newer
            return prevTeamTimers;
          };

          // Update the teamTimers state with the new message data if the timestamp is newer
          return {
            ...prevTeamTimers,
            [parsedMessage.user_id]: {
              ...existingData,    // Spread existing data if it exists
              ...parsedMessage,   // Overwrite with the new parsedMessage data
            },
          };
        });
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    });

    setClient(client);

    // Cleanup function to disconnect the client when the component unmounts
    return () => {
      client.end();
    };
  }, []);

  useEffect(() => {
    // Ensure that client and user are available before proceeding
    if (!client || !user || !user.id || !user.username) return; // Pastikan client dan user tersedia

    // Set an interval to publish a message every 5 seconds
    const publishTimer = setInterval(() => {
      const message = {
        user_id: user.id,
        username: user.username,
        status: isRunning ? activeMode : 'Idle',
        created_at: Date.now(),
      };

      // Define the topic for publishing the message
      const topic = `multi-pomodoro/${user.id}`;
      client.publish(topic, JSON.stringify(message), { qos: 1 }, (err) => {
        if (err) {
          console.error('Failed to publish:', err);
        } else {
          console.log(`Message published to ${topic}`);
        }
      });
    }, 5000); // Publish message every 5 seconds

    // Clean up by clearing the interval when the component is unmounted or dependencies change
    return () => clearInterval(publishTimer);
  }, [client, user, activeMode, isRunning]);

  // Runs the `saveDataToDB` function whenever the `teamTimers` state changes
  useEffect(() => {
    saveDataToDB();
  }, [teamTimers, saveDataToDB]);


  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
      setActiveMode(null);

      if (Notification.permission === "granted") {
        new Notification("Pomodoro Timer", {
          body: "Your Pomodoro timer has ended",
        });
      }
    }

    return () => clearInterval(timer);
  }, [isRunning, time]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
  };

  const handleStart = () => {
    if (!time || 0) {
      alert("Please select mode!")
      return;
    };

    if (!user || !user.id || !user.email || !user.username) {
      alert("Please enter your email, username, and save!");
      return;
    };

    setIsRunning(!isRunning);
  };

  const handleAddTask = () => {
    setIsAddingTask(true);
  };

  const handleCancel = () => {
    setIsAddingTask(false);
    setNewTask('');
  };

  const handleSave = () => {
    if (newTask.trim()) {
      setTasks((prevTasks) => [
        ...prevTasks,
        { name: newTask, completed: false }
      ]);
      setIsAddingTask(false);
      setNewTask('');
    }
  };

  const handleEditTask = (index: number) => {
    setEditIndex(index);
    setTaskEditText(tasks[index].name);
  };

  const handleSaveTask = () => {
    if (editIndex !== null) {
      const updatedTasks = tasks.map((task, index) =>
        index === editIndex ? { ...task, name: taskEditText } : task
      );
      setTasks(updatedTasks);
      setEditIndex(null);
      setTaskEditText("");
    }
  };

  const handleDeleteTask = (index: number) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleCompleteTask = (index: number) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
  };

  const handleIntervalChange = (seconds: number, mode: string) => {
    setTime(seconds);
    setIsRunning(false);
    setActiveMode(mode);
  };

  const soundMap = useMemo(() => ({
    "Forest 1": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/rainforest_1.mp3",
    "Forest 2": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/rainforest_2.mp3",
    "Construction 2": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/construction_1.mp3",
  }), []);

  const handleSoundChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSound = e.target.value;
    setAmbientSound(newSound);

    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    }

    const newAudio = new Audio(soundMap[newSound]);
    newAudio.loop = true;
    newAudio.play();
    setAudioInstance(newAudio);
    setIsPlaying(true);
  }, [audioInstance, soundMap]);

  useEffect(() => {
    if (isPlaying && audioInstance) {
      audioInstance.play();
    } else if (audioInstance) {
      audioInstance.pause();
    }

    return () => {
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.currentTime = 0;
      }
    };
  }, [isPlaying, audioInstance]);

  const handleTogglePlayStop = () => {
    setIsPlaying(prev => !prev);
  };

  return (
    <div className="mx-6 my-2">
      {/* Title */}
      <div className="flex items-center mb-3">
        <div className="title flex items-center font-bold space-x-2">
          <span>Pomodoro Time</span>
          <WorkInProgressBadge />
        </div>
      </div>

      {/* Main Container */}
      <div className="flex flex-row gap-4">
        {/* Left Container */}
        <div className="w-2/3 flex flex-col gap-4 ">
          <div className="main">
            <p className="font-bold mb-3">Main</p>
            <div className="bg-gray-900 rounded-lg p-6">
              {/* First Content */}
              <div className="flex justify-center items-center mb-6 flex-wrap ">
                <button
                  className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'focus' ? 'bg-zinc-950' : 'bg-purple-800'}`}
                  onClick={() => handleIntervalChange(1500, 'focus')}
                  disabled={isRunning}
                >
                  Focus Mode (25mins)
                </button>
                <button
                  className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'longBreak' ? 'bg-zinc-950' : 'bg-purple-800'}`}
                  onClick={() => handleIntervalChange(600, 'longBreak')}
                  disabled={isRunning}
                >
                  Long Break (10mins)
                </button>
                <button
                  className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'shortBreak' ? 'bg-zinc-950' : 'bg-purple-800'}`}
                  onClick={() => handleIntervalChange(300, 'shortBreak')}
                  disabled={isRunning}
                >
                  Short Break (5mins)
                </button>
              </div>
              <div className="text-center text-white flex-grow flex items-center justify-center">
                <span className="font-bold" style={{ fontSize: "7vw" }}>
                  {formatTime(time)}
                </span>
              </div>
              <div className="flex justify-center w-full">
                <button
                  className="btn btn-sm bg-purple-800 text-white w-auto px-9"
                  onClick={handleStart}
                >
                  {isRunning ? "Pause" : "Start"}
                </button>
              </div>
            </div>
          </div>
          {/* Second Content */}
          <div className="Task">
            <div className="bg-transparent mb-7 flex-grow">
              <div className="flex items-center mb-4">
                <h1 className="font-bold mr-4 text-left">Tasks</h1>
                <div className="flex items-center flex-grow">
                  <input
                    placeholder="Tasks"
                    className="max-w-[500px] w-72 input input-bordered input-sm mr-2"
                  />
                </div>
              </div>
              <div className="overflow-y-auto max-h-[30vh]">
                {tasks.map((task, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border border-gray-300 rounded-lg p-2 mb-2 "
                  >
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={task.completed}
                        onChange={() => handleCompleteTask(index)}
                      />
                      {editIndex === index ? (
                        <input
                          type="text"
                          value={taskEditText}
                          onChange={(e) => setTaskEditText(e.target.value)}
                          className="input input-sm input-bordered"
                        />
                      ) : (
                        <span
                          style={{
                            textDecoration: task.completed ? "line-through" : "none",
                          }}
                        >
                          {task.name}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      {editIndex === index ? (
                        <button onClick={handleSaveTask} className="text-green-500 mr-2">
                          Save
                        </button>
                      ) : (
                        <button onClick={() => handleEditTask(index)} className="text-blue-500 mr-2">
                          Edit
                        </button>
                      )}
                      <button>
                        <DeleteButton onDelete={() => handleDeleteTask(index)} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {isAddingTask ? (
                <div className="w-full bg-gray-900 border border-gray-900 rounded-lg p-4 shadow-md">
                  {/* Input field yang muncul ketika "Add Task +" diklik */}
                  <input
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                    placeholder="What are you working on?"
                    className="w-full h-16 p-2 border border-gray-900 rounded-md mb-2"
                  />
                  {/* Tombol Cancel dan Save */}
                  <div className="flex justify-end">
                    <button onClick={handleCancel} className="btn btn-sm mx-2 text-white bg-gray-700">
                      Cancel
                    </button>
                    <button onClick={handleSave} className="btn btn-sm text-white bg-purple-800">
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <div className="p-4 border-dashed border rounded-lg mt-4 cursor-pointer hover:bg-purple-800 hover:border-solid transition duration-200">
                  <button onClick={handleAddTask} className="w-full text-white">
                    Add Task +
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* Third Content */}
          <div className="Settings">
            <p className="font-bold mb-4 text-left">Settings</p>
            <div role="tablist" className="tabs tabs-lifted">
              {/* Ambient */}
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Ambient"
                defaultChecked
              />
              <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <label className="mr-2">Ambient</label>
                    <select
                      value={ambientSound}
                      onChange={handleSoundChange}
                      className="select select-bordered select-xs max-w-xs"
                    >
                      {Object.keys(soundMap).map((sound) => (
                        <option value={sound} key={sound}>{sound}</option>
                      ))}
                    </select>
                  </div>
                  <button
                    onClick={handleTogglePlayStop}
                    className="btn btn-sm bg-purple-800 text-white w-auto"
                  >
                    {isPlaying ? "Stop" : "Play"}
                  </button>
                </div>
              </div>
              {/* Account */}
              <input
                type="radio"
                name="my_tabs_2"
                role="tab"
                className="tab"
                aria-label="Account"
              />
              <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                <div className="flex flex-col gap-2">
                  <div className="email w-full">
                    <h1 className="text-left">Email:</h1>
                    <div className="flex items-center flex-grow">
                      <input
                        type="email"
                        placeholder="Email"
                        className="w-full input input-bordered input-sm"
                        value={tempEmail}
                        onChange={(e) => setTempEmail(e.target.value)}
                        disabled={isRunning}
                      />
                    </div>
                  </div>
                  <div className="username w-full">
                    <h1 className="text-left">Username:</h1>
                    <div className="flex items-center flex-grow">
                      <input
                        placeholder="Username"
                        className="w-full input input-bordered input-sm"
                        value={tempUsername}
                        onChange={(e) => setTempUsername(e.target.value)}
                        disabled={isRunning}
                      />
                    </div>
                  </div>
                  <div className="save w-full">
                    <button
                      className="btn btn-sm bg-purple-800 text-white w-full px-9"
                      onClick={handleSaveUser}
                      disabled={isRunning}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Side */}
        <div className="w-1/3">
          <p className="font-bold mb-3">Team Timers</p>
          <div className="bg-transparent rounded-lg border-gray-300 border p-6 flex flex-col h-full overflow-y-auto">
            <div className="flex flex-col flex-grow gap-4">
              {Object.values(teamTimers).map((timer, index) => (
                <div key={index} className="flex items-center justify-between border border-gray-300 rounded-lg p-2 w-full box-border">
                  <span className="font-bold">@{timer.username}</span>
                  <span className="ml-1 flex-grow truncate">{timer.status}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pomodoro;