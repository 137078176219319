import { useEffect, useState } from "react";
import seedrandom from "seedrandom";
import toast from "react-hot-toast";

const uppercaseLib = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const lowercaseLib = "abcdefghijklmnopqrstuvwxyz";
const digitsLib = "0123456789";
const symbolsLib = "!@#$%^&*()-_=+[]{}|;:'\"<>,.?/";

const shuffleArray = (combinedArray: string[]): string[] => {
  // Combine the arrays

  // Initialize the result array
  let result = [];

  // Randomly select characters from combinedArray
  for (let i = 0; i < combinedArray.length; i++) {
    // Generate a random index within the combinedArray length
    let randomIndex = Math.floor(Math.random() * combinedArray.length);

    // Add the randomly selected character to the result array
    result.push(combinedArray[randomIndex]);
  }

  return result;
}

const colors = ["text-blue-500", "text-green-500", "text-red-500"];

const getTextColorClass = (char: string) => {
  let textColorClass = "";
  if (/[0-9]/.test(char)) {
    // Numbers are blue
    textColorClass = colors[0];
  } else if (/[A-Z]/.test(char)) {
    // Uppercase are green
    textColorClass = colors[1];
  } else if (/[!@#$%^&*()-_=+[\]{}|;:'"<>,.?/]/.test(char)) {
    // Symbols are red
    textColorClass = colors[2];
  }
  return textColorClass
}

export default function RandomStringGenerator() {
  const [uppercaseChar, setUppercaseChar] = useState(40);
  const [lowercaseChar, setLowercaseChar] = useState(40);
  const [symbols, setSymbols] = useState(40);
  const [digits, setDigits] = useState(40);
  const [seed, setSeed] = useState("");
  const [lengthLine, setLengthLine] = useState(1);
  const [output, setOutput] = useState("");
  const [colorChecked, setColorChecked] = useState(true);

  const handleRangeUppercase = (e) => {
    const uppercaseValue = parseInt(e.target.value, 10);
    setUppercaseChar(uppercaseValue);
  };

  const handleRangeLowercase = (e) => {
    const lowercaseValue = parseInt(e.target.value, 10);
    setLowercaseChar(lowercaseValue);
  };

  const handleRangeSymbols = (e) => {
    const symbolsValue = parseInt(e.target.value, 10);
    setSymbols(symbolsValue);
  };

  const handleRangeDigits = (e) => {
    const digitsValue = parseInt(e.target.value, 10);
    setDigits(digitsValue);
  };

  const handleRandomSeed = () => {
    const newSeed = Math.random().toString(36).substring(2, 10);
    setSeed(newSeed);
  };

  const copyToClipboard = () => {
    // Remove unnecessary span tags before copying to clipboard
    const removeTextOutputSpan = output.replace(/<\/?span[^>]*>/g, "").replace(/<br\s*\/?>/g, "\n");
    navigator.clipboard.writeText(removeTextOutputSpan);
    toast.success("Copied to clipboard!");
  };

  useEffect(() => {
    // Initial
    const randomSeed = seedrandom(seed);

    let mixedOutputFinalWithHtml = "";

    let characters = uppercaseChar + lowercaseChar + digits + symbols;
    if (characters === 0) {
      toast.error("Select at least one character option.");
      return;
    }

    // Iterate
    for (let j = 0; j < lengthLine; j++) {
      let randomStringForUppercaseWithHtml = [];
      let randomStringForUppercaseWithoutHtml = [];
      let randomStringForLowercaseWithHtml = [];
      let randomStringForLowercaseWithoutHtml = [];
      let randomStringForSymbolsWithHtml = [];
      let randomStringForSymbolsWithoutHtml = [];
      let randomStringForDigitsWithHtml = [];
      let randomStringForDigitsWithoutHtml = [];

      let combinedArrayWithHtml = [];
      let combinedArrayWithoutHtml = [];

      // Generate random for each category 
      // Uppercase
      for (let i = 0; i < uppercaseChar; i++) {
        const randomIndex = Math.floor(randomSeed() * Math.random() * uppercaseLib.length);
        randomStringForUppercaseWithHtml.push(`<span class="${getTextColorClass(uppercaseLib[randomIndex])}">${uppercaseLib[randomIndex]}</span>`);
        randomStringForUppercaseWithoutHtml.push(uppercaseLib[randomIndex]);
      }
      // Lowercase
      for (let i = 0; i < lowercaseChar; i++) {
        const randomIndex = Math.floor(randomSeed() * Math.random() * lowercaseLib.length);
        randomStringForLowercaseWithHtml.push(`<span class="${getTextColorClass(lowercaseLib[randomIndex])}">${lowercaseLib[randomIndex]}</span>`);
        randomStringForLowercaseWithoutHtml.push(lowercaseLib[randomIndex]);
      }
      // Symbols
      for (let i = 0; i < symbols; i++) {
        const randomIndex = Math.floor(randomSeed() * Math.random() * symbolsLib.length);
        randomStringForSymbolsWithHtml.push(`<span class="${getTextColorClass(symbolsLib[randomIndex])}">${symbolsLib[randomIndex]}</span>`);
        randomStringForSymbolsWithoutHtml.push(symbolsLib[randomIndex]);
      }
      // Digits
      for (let i = 0; i < digits; i++) {
        const randomIndex = Math.floor(randomSeed() * Math.random() * digitsLib.length);
        randomStringForDigitsWithHtml.push(`<span class="${getTextColorClass(digitsLib[randomIndex])}">${digitsLib[randomIndex]}</span>`);
        randomStringForDigitsWithoutHtml.push(digitsLib[randomIndex]);
      }

      // Join random string for each category
      combinedArrayWithHtml = combinedArrayWithHtml.concat(randomStringForUppercaseWithHtml);
      combinedArrayWithHtml = combinedArrayWithHtml.concat(randomStringForLowercaseWithHtml);
      combinedArrayWithHtml = combinedArrayWithHtml.concat(randomStringForSymbolsWithHtml);
      combinedArrayWithHtml = combinedArrayWithHtml.concat(randomStringForDigitsWithHtml);
      combinedArrayWithoutHtml = combinedArrayWithoutHtml.concat(randomStringForUppercaseWithoutHtml);
      combinedArrayWithoutHtml = combinedArrayWithoutHtml.concat(randomStringForLowercaseWithoutHtml);
      combinedArrayWithoutHtml = combinedArrayWithoutHtml.concat(randomStringForSymbolsWithoutHtml);
      combinedArrayWithoutHtml = combinedArrayWithoutHtml.concat(randomStringForDigitsWithoutHtml);

      // Shuffle 
      combinedArrayWithHtml = shuffleArray(combinedArrayWithHtml);
      combinedArrayWithoutHtml = shuffleArray(combinedArrayWithoutHtml);

      // Add line 
      combinedArrayWithHtml.push("<br />");
      combinedArrayWithoutHtml.push("\n");

      mixedOutputFinalWithHtml += combinedArrayWithHtml.join("");
    };

    console.log(mixedOutputFinalWithHtml);

    // Set
    setOutput(mixedOutputFinalWithHtml);
  }, [uppercaseChar, lowercaseChar, digits, symbols, lengthLine, seed]);

  const filterColor = (input: string) => {
    // Function to replace all occurrences of a substring in a string using a regular expression
    const replaceAll = (input: string, find: string, replace: string) => {
      return input.replace(new RegExp(find, 'g'), replace);
    };

    // Replace all possible color classes with "text-white"
    colors.forEach((colorClass: string) => {
      const regex = `\\b${colorClass}\\b`;
      input = replaceAll(input, regex, "text-white");
    });

    return input;
  };

  useEffect(() => {
    handleRandomSeed();
  }, []);

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">Random String Generator</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex gap-1 items-center my-2">
              Seed:
              <input type="text" value={seed} onChange={(e) => setSeed(e.target.value)} placeholder="Type here" className="input input-bordered input-sm w-full max-w-xs" />
              <button className="btn btn-sm" name="random_seed" onClick={handleRandomSeed}>
                random seed
              </button>
            </div>

            <div className="my-3">
              <div className={`flex justify-between items-center mb-2 ${colors[1]}`}>
                Uppercased Characters:
                <input type="number" className="px-1 max-w-[50px] text-center" value={uppercaseChar} onChange={(e) => setUppercaseChar(parseInt(e.target.value, 10))} />
              </div>
              <input type="range" min={0} max={100} value={uppercaseChar} onChange={handleRangeUppercase} className="range range-xs" />
            </div>

            <div className="my-3">
              <div className={`flex justify-between items-center mb-2 text-white`}>
                Lowercased Characters:
                <input type="number" className="px-1 max-w-[50px] text-center" value={lowercaseChar} onChange={(e) => setLowercaseChar(parseInt(e.target.value, 10))} />
              </div>
              <input type="range" min={0} max={100} value={lowercaseChar} onChange={handleRangeLowercase} className="range range-xs" />
            </div>

            <div className="my-3">
              <div className={`flex justify-between items-center mb-2  ${colors[2]}`}>
                Symbols:
                <input type="number" className="px-1 max-w-[50px] text-center" value={symbols} onChange={(e) => setSymbols(parseInt(e.target.value, 10))} />
              </div>
              <input type="range" min={0} max={100} value={symbols} onChange={handleRangeSymbols} className="range range-xs" />
            </div>

            <div className="my-3">
              <div className={`flex justify-between items-center mb-2  ${colors[0]}`}>
                Digits:
                <input type="number" className="px-1 max-w-[50px] text-center" value={digits} onChange={(e) => setDigits(parseInt(e.target.value, 10))} />
              </div>
              <input type="range" min={0} max={100} value={digits} onChange={handleRangeDigits} className="range range-xs" />
            </div>
          </div>

          <div>
            <div className="flex justify-end gap-2 items-center">
              <div className="form-control">
                <label className="cursor-pointer flex items-center gap-1">
                  {/* if checkbox true= color-text of symbols = red, text-color of number = blue */}
                  <input type="checkbox" className="checkbox checkbox-info" onChange={() => setColorChecked(!colorChecked)} checked={colorChecked} />
                  <span className="label-text">Color</span>
                </label>
              </div>
              <input
                type="number"
                name="valuetotimes"
                // banyaknya baris
                value={lengthLine}
                min={1}
                onChange={(e) => setLengthLine(parseInt(e.target.value, 10))}
                className="bg-gray-900 w-14 text-center rounded-md border border-gray-700 focus:border-blue-500 text-white"
              />
              <button className="btn btn-sm" onClick={copyToClipboard}>
                Copy
              </button>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: colorChecked === true ? output : filterColor(output) }}
              className={`break-words bg-gray-900 overflow-auto w-full p-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
