import { useCallback, useEffect, useState } from "react";
import sampleLorem from "../assets/json/lorem.json";
import CopyButton from "../components/CopyButton";
import btoa from "btoa-lite";

export default function Base64Converter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isEncoding, setIsEncoding] = useState(true);
  const [invalidBase64, setInvalidBase64] = useState("");
  const [inputTextStats, setInputTextStats] = useState(null);
  const [outputTextStats, setOutputTextStats] = useState(null);

  const calculateTextStats = (text: string) => {
    const trimmedText = text.trim();
    if (!trimmedText) {
      return {
        word: 0,
        char: 0,
        size: 0,
      };
    };

    const wordCount = text.split(/\s+/).length;
    const charCount = text.length;
    const sizeCount = new TextEncoder().encode(text).length / 1024;

    return {
      word: wordCount,
      char: charCount,
      size: sizeCount,
    };
  };

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setInputText(newText);
    convertText(newText);
  };

  const convertText = useCallback(
    (text) => {
      try {
        if (isEncoding) {
          const encoder = new TextEncoder();
          const encodedString = btoa(
            String.fromCharCode.apply(null, encoder.encode(text))
          );
          setOutputText(encodedString);
        } else {
          const decodedString = decodeURIComponent(
            atob(text)
              .split("")
              .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          setOutputText(decodedString);
        }
        setInvalidBase64("");
      } catch (error) {
        setOutputText(isEncoding ? "Error encoding text" : inputText);
        setInvalidBase64("Invalid base64 string");
      }
    },
    [isEncoding, inputText]
  );

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
      convertText(clipboardText);
    } catch (error) {
      console.error("Something Error: " + error);
    }
  };

  const handleSampleFromLorem = async () => {
    setInputText(isEncoding ? sampleLorem[0] : btoa(sampleLorem[0]));
    convertText(isEncoding ? sampleLorem[0] : btoa(sampleLorem[0]));
  };

  const handleClear = async () => {
    setInputText("");
    setOutputText("");
  };

  const handleUseAsInput = () => {
    setInputText(outputText);
    convertText(outputText);
  };

  useEffect(() => {
    convertText(inputText);
    setInputTextStats(calculateTextStats(inputText));
    setOutputTextStats(calculateTextStats(outputText));
  }, [inputText, outputText, isEncoding, convertText]);

  return (
    <div className="h-full flex">
      {/* Main Content */}
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">
          BASE64 {isEncoding ? "Encode" : "Decode"}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <div className="inline-block">Input:</div>
                <button
                  className="btn btn-xs mx-1"
                  onClick={handlePasteFromClipboard}
                >
                  Clipboard
                </button>
                <button
                  className="btn btn-xs  mr-1"
                  onClick={handleSampleFromLorem}
                >
                  Sample
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
              <div className="flex">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="radio-10"
                      className="radio checked:bg-blue-500"
                      checked={isEncoding}
                      onChange={() => setIsEncoding(true)}
                    />
                    <span className="label-text pl-1.5">Encode</span>
                  </label>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="radio-10"
                      className="radio checked:bg-blue-500"
                      checked={!isEncoding}
                      onChange={() => setIsEncoding(false)}
                    />
                    <span className="label-text pl-1.5">Decode</span>
                  </label>
                </div>
              </div>
            </div>
            <textarea
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[37vh] max-h-[78.5vh] text-white"
              placeholder={`Enter text to ${isEncoding ? "encode" : "decode"
                } here`}
              value={inputText}
              onChange={handleInputChange}
            />
            <div className="w-full flex justify-end items-center mt-3">
              <span>Words: {inputTextStats ? inputTextStats.word : 0}</span>
              <span className="mx-2">|</span>
              <span>Chars: {inputTextStats ? inputTextStats.char : 0}</span>
              <span className="mx-2">|</span>
              <span>Size: {inputTextStats ? inputTextStats.size.toFixed(2) : 0} KB</span>
            </div>
          </div>
          <div>
            <div className="flex justify-between mb-3">
              <p className="py-1">Output:
                {invalidBase64 && <small className="text-red-500 pl-2">{invalidBase64}</small>}
              </p>
              <div className="flex gap-3">
                <CopyButton text={outputText} />
                <button className="btn btn-xs" onClick={handleUseAsInput}>
                  use as input ⏫
                </button>
              </div>
            </div>
            <textarea
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[37vh] max-h-[78.5vh] text-white"
              placeholder={`${isEncoding ? "Encoded" : "Decoded"
                } text will appear here`}
              value={outputText}
              readOnly
            />
              <div className="w-full flex justify-end items-center mt-3">
                <span>Words: {outputTextStats ? outputTextStats.word : 0}</span>
                <span className="mx-2">|</span>
                <span>Chars: {outputTextStats ? outputTextStats.char : 0}</span>
                <span className="mx-2">|</span>
                <span>Size: {outputTextStats ? outputTextStats.size.toFixed(2) : 0} KB</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
